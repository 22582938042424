import type { AppAction } from 'src/store/actions';
import z from 'zod';

export const NativeStateSchema = z.object({
    showTickers: z.boolean().default(true),
});

export type NativeState = z.infer<typeof NativeStateSchema>;

export const initialState: NativeState = {
    showTickers: true,
};

export const native = (state = initialState, action: AppAction): NativeState => {
    switch (action.type) {
        case 'native::init':
            return { ...state, ...action.payload };
        case 'native::tickers-hide':
            return { ...state, showTickers: false };
        case 'native::tickers-show':
            return { ...state, showTickers: true };
        default:
            return state;
    }
};
