import type { AppAction } from '../actions';
import type { ContainerLayoutViewModel } from '../types';
import { keyArrayToRecord } from '../utils';

export interface LayoutState {
    byContainerId: Record<string, ContainerLayoutViewModel>;
}

export const initialState: LayoutState = {
    byContainerId: {},
};

export const layout = (state = initialState, action: AppAction): LayoutState => {
    switch (action.type) {
        case 'initializeWorkspace': {
            return {
                ...state,
                byContainerId: {
                    ...state.byContainerId,
                    ...keyArrayToRecord(action.layouts, (l) => l.containerId),
                },
            };
        }

        case 'updateWorkspaceLayout': {
            return {
                ...state,
                byContainerId: {
                    ...state.byContainerId,
                    [action.containerId]: {
                        ...(state.byContainerId[action.containerId] ?? {}),
                        ...action.layoutUpdates,
                    },
                },
            };
        }

        case 'addContainer': {
            return {
                ...state,
                byContainerId: {
                    ...state.byContainerId,
                    [action.layout.containerId]: action.layout,
                },
            };
        }

        case 'removeContainer': {
            const updatedLayoutsByKey: Record<string, ContainerLayoutViewModel> = { ...state.byContainerId };
            delete updatedLayoutsByKey[action.container.id];

            return {
                ...state,
                byContainerId: {
                    ...updatedLayoutsByKey,
                },
            };
        }

        default:
            return state;
    }
};
