import type { AppAction } from 'src/store/actions';

interface ApplicationState {
    bootstrapped: boolean;
    active: boolean;
    appVersionStale: boolean;
    appVersion?: string;
}

export const initialState: ApplicationState = {
    bootstrapped: false,
    active: true,
    appVersionStale: false,
    appVersion: undefined,
};

export const app = (state = initialState, action: AppAction): ApplicationState => {
    switch (action.type) {
        case 'bootstrap::begin': {
            return { ...state, bootstrapped: false };
        }

        case 'bootstrap::complete': {
            return { ...state, bootstrapped: true };
        }

        case 'active-app::claim': {
            return { ...state, active: true };
        }

        case 'active-app::unclaim': {
            return { ...state, active: false };
        }

        case 'app-version::stale': {
            return { ...state, appVersionStale: true };
        }

        case 'app-version::stale-acknowledged': {
            return { ...state, appVersionStale: false };
        }

        case 'app-version::set': {
            return { ...state, appVersion: action.version };
        }

        default:
            return state;
    }
};
