// What's with the random text at the end of the tokens, you may ask
// We may have accidentally reused a key for a different data type at some point
// (which we can avoid in the future by not storing individual things, but rather having a
// single object for all storage that we can run through migrations)
// so we're going to try invalidating all the keys with a random uuid split
// up and appended to all our keys. We should remove this, but I'm
// going to timestamp this to see how long it takes us to do that
// Phil Barresi, October 11, 2024
// Amended by Phil Barresi, October 26, 2024, because things did not go so great
// and we are forcing all these keys to blank out logins again for auth
// but just the login stuff. c7cc6de4-52b2-4801-afbf-aaf793854d40
export const MASQ_ACCESS_TOKEN_KEY = 'masq_access_token_c7cc6de4';
export const ACCESS_TOKEN_KEY = 'access_token_52b2';
export const REFRESH_TOKEN_KEY = 'refresh_token_4801';
export const PKCE_PAIR_KEY = 'pkce_pair_afbf';
export const OAUTH_STATE_KEY = 'oauth_state_aaf793854d40';
export const SYMBOL_CACHE_KEY = 'symbol_cache_182f';
