import { IndicatorImportModel } from '../IndicatorImport';
import type { ConcreteIndicatorViewModel, IndicatorImportRefViewModel } from 'src/contracts/dictionary-view-model';

export class IndicatorImportRefModel {
    #alias: string;
    #id: string;
    #version: number;

    get alias(): string {
        return this.#alias;
    }

    private constructor({ alias, id, version }: { alias: string; id: string; version: number }) {
        this.#alias = alias;
        this.#id = id;
        this.#version = version;
    }

    static fromIndicatorImportRefViewModel(viewModel: IndicatorImportRefViewModel): IndicatorImportRefModel {
        return new IndicatorImportRefModel(viewModel);
    }

    get id(): string {
        return this.#id;
    }

    toContractType(): IndicatorImportRefViewModel {
        return {
            alias: this.#alias,
            id: this.#id,
            version: this.#version,
        };
    }

    /**
     * Materialize the ref model
     */
    toMaterialized(concreteIndicator: ConcreteIndicatorViewModel): IndicatorImportModel {
        return IndicatorImportModel.fromIndicatorImportViewModel({
            alias: this.#alias,
            ...concreteIndicator,
        });
    }

    get version(): number {
        return this.#version;
    }
}
