import { useDispatch, useSelector } from '../../store/hooks';
import { replace } from 'redux-first-history';
import { SpinnerOverlay } from 'src/components/spinner/spinner';
import { useUser } from 'src/hooks/state';

type RequireSuperuserAuthProps = {
    children: React.ReactNode;
    fallbackURL?: string;
};

export const RequireSuperuserAuth = ({ children, fallbackURL = '/dashboard' }: RequireSuperuserAuthProps) => {
    const user = useUser();
    const dispatch = useDispatch();
    const superuserAccessEnabledUntil = useSelector((state) => Boolean(state.ui.superuserAccessEnabledUntil));
    const isSuperuserEnabled = user?.isSuperuser && superuserAccessEnabledUntil;

    if (user && !isSuperuserEnabled) {
        dispatch(replace(fallbackURL));
        return null;
    } else if (!user) {
        return <SpinnerOverlay />;
    }

    return <>{children}</>;
};

export function useIsSuperuserEnabled(): boolean {
    const user = useUser();
    const superuserAccessEnabledUntil = useSelector((state) => Boolean(state.ui.superuserAccessEnabledUntil));
    return Boolean(user?.isSuperuser && superuserAccessEnabledUntil);
}
