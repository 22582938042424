export enum InstrumentType {
    equity = 'equity',
}

export interface IInstrument {
    type: InstrumentType;
}

export interface EquityInstrument extends IInstrument {
    type: InstrumentType.equity;
    symbol: string;
}

export type Instrument = EquityInstrument;

export enum ShortStatus {
    LocateRequired = 'LocateRequired',
    EasyToBorrow = 'EasyToBorrow',
    PreBorrow = 'Preborrow',
}

export interface IInstrumentDetails {
    cusip: string;
    isin: string;
    prevCusips: string[];
    // previousIsins: string[];
    currency: string;
    country: string;
    shortStatus?: ShortStatus;
}

export interface EquityInstrumentDetails extends EquityInstrument, IInstrumentDetails {
    etf: boolean;
    gic: number;
    name: string;
    prevSymbols: string[];
}

export type InstrumentDetails = EquityInstrumentDetails;
