import type { AppAction } from '../actions';
import { intentBasedActionTypes } from '../actions';
import type { Middleware } from 'redux';

export const intentBlockingMiddleware: Middleware = () => (next) => (action: AppAction) => {
    if (intentBasedActionTypes.includes(action.type) && !action.isIntentConfirmed) {
        next({ type: 'verifyIntentAction', originalAction: action });
    } else {
        next(action);
    }
};
