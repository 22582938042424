import { Instant } from '@js-joda/core';
import type { ParserResult } from '@thinkalpha/language-services';
import { AstNodeType, FieldTypeCategory } from '@thinkalpha/language-services';

export * from './types';

export function fakeConstStringParserResult<TExtraData extends object | undefined = any>(
    text: string,
    extraData?: TExtraData,
): ParserResult & { extraData: TExtraData | undefined } {
    return {
        errors: [],
        extraData,
        valid: true,
        text,
        timestamp: Instant.now(),
        root: {
            type: AstNodeType.const,
            path: '',
            errors: [],
            ranges: {
                node: { start: 0, end: text.length },
                value: { start: 0, end: text.length },
            },
            value: text,
            valid: true,
            warnings: [],
            dataType: FieldTypeCategory.String,
        },
        warnings: [],
    };
}
