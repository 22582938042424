import type { PositionsActions } from 'src/store/actions/widgets/positions';
import type { PositionsWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const positionsReducer = (
    state: WidgetTabViewModel<PositionsWidgetViewModel>,
    action: PositionsActions,
): WidgetTabViewModel<PositionsWidgetViewModel> => {
    switch (action.type) {
        case 'updatePositionsAccount': {
            return { ...state, widget: { ...state.widget, accountIds: [action.accountId] } };
        }
        case 'updatePositionsSymbolFilter': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbolFilter: action.symbol,
                },
            };
        }

        default:
            return state;
    }
};
