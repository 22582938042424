import type { RootState } from './index';
import type { SelectEffect, Tail } from 'redux-saga/effects';
import { select as baseSelect } from 'redux-saga/effects';

export function select<Fn extends (state: RootState, ...args: any[]) => any>(
    selector: Fn,
    ...args: Tail<Parameters<Fn>>
): SelectEffect {
    return baseSelect(selector, ...args);
}
