import type { AppAction } from '../actions';

export interface ThemeState {
    theme: 'light' | 'dark' | 'system';
}

export const initialState: ThemeState = {
    // get the theme from local storage or set it to 'dark' if it's not set
    theme: 'dark',
    //theme: (localStorage.getItem('theme') as 'light' | 'dark' | 'system') || 'dark',
};

export const theme = (state = initialState, action: AppAction): ThemeState => {
    switch (action.type) {
        case 'theme::set': {
            return {
                ...state,
                theme: action.theme,
            };
        }

        default:
            return state;
    }
};
