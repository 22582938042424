import type { FunctionDef, ParameterDef } from '@thinkalpha/language-services';
import type { IndicatorTemplateColumn } from 'src/contracts/column-template';
import type {
    ConcreteIndicatorViewModel,
    FormulaPresetMatchViewModel,
    IndicatorFormulaViewModel,
    IndicatorImportViewModel,
    IndicatorParameterViewModel,
    PresetMatchViewModel,
} from 'src/contracts/dictionary-view-model';

export type IndicatorOrImport = ConcreteIndicatorViewModel | IndicatorImportViewModel;

export function isIndicatorImport(
    item: ConcreteIndicatorViewModel | IndicatorImportViewModel,
): item is IndicatorImportViewModel {
    return (item as IndicatorImportViewModel).alias !== undefined;
}

export function isIndicatorParameters(
    params: ParameterDef[] | IndicatorParameterViewModel[],
): params is IndicatorParameterViewModel[] {
    return !!(params as IndicatorParameterViewModel[]).map((x) => x.bars);
}

export interface DictionaryFunctionDef extends FunctionDef {
    indicator: IndicatorOrImport;
    alias?: string;
}

export function isDictionaryFunctionDef(x: DictionaryFunctionDef | FunctionDef): x is DictionaryFunctionDef {
    return !!(x as DictionaryFunctionDef).indicator;
}

export function isFormulaPresetMatch(preset: PresetMatchViewModel): preset is FormulaPresetMatchViewModel {
    return !!(preset as FormulaPresetMatchViewModel).imports;
}

export type IndImportDiffSettings = {
    prevImport: IndicatorImportViewModel;
    newInd: ConcreteIndicatorViewModel;
};

export type IndImportDiff = {
    version: { prev: number; new: number };
    name?: { prev: string; new: string };
    symbol?: { prev: string; new: string };
    formula?: { prev: string; new: string };
    imports?: { prev: string; new: string };
    description?: { prev: string; new: string };
    synonyms?: { prev: string[]; new: string[] };
    tags?: { prev: string[]; new: string[] };
    notes?: { prev: string; new: string };
};

export function isIndicatorFormula(
    formula: IndicatorFormulaViewModel | IndicatorTemplateColumn,
): formula is IndicatorFormulaViewModel {
    return !!(formula as IndicatorFormulaViewModel).imports;
}
