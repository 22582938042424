/**
 * @jest-environment jsdom
 */
import { Instant } from '@js-joda/core';
import { select, take } from 'redux-saga/effects';
import { ACCESS_TOKEN_KEY, MASQ_ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/constants/storage-keys';
import { localforage } from 'src/lib/serialization/localForage';
import type { RootState } from 'src/store';
import type { SetTokenAction } from 'src/store/actions/auth';
import type { AuthState, TokenState } from 'src/store/reducers/auth';

export async function retrieveStoredAccessToken() {
    return (await localforage.getItem<TokenState>(ACCESS_TOKEN_KEY)) ?? null;
}

export async function storeAccessToken(accessToken: TokenState | null) {
    return await localforage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export async function clearStoredAccessToken() {
    return await localforage.removeItem(ACCESS_TOKEN_KEY);
}

export async function retrieveStoredRefreshToken() {
    return (await localforage.getItem<TokenState>(REFRESH_TOKEN_KEY)) ?? null;
}

export async function storeRefreshToken(refreshToken: TokenState | null) {
    return await localforage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export async function clearStoredRefreshToken() {
    return await localforage.removeItem(REFRESH_TOKEN_KEY);
}

export async function retrieveStoredMasqToken() {
    return (await localforage.getItem<TokenState>(MASQ_ACCESS_TOKEN_KEY)) ?? null;
}

export async function storeMasqToken(masqToken: TokenState | null) {
    return await localforage.setItem(MASQ_ACCESS_TOKEN_KEY, masqToken);
}

export async function clearStoredMasqToken() {
    return await localforage.removeItem(MASQ_ACCESS_TOKEN_KEY);
}

export function* waitForAuth() {
    let [accessToken, masqAccessToken]: [AuthState['accessToken'], AuthState['masqAccessToken']] = yield select(
        (store: RootState) => [store.auth.accessToken, store.auth.masqAccessToken],
    );

    while (
        (!accessToken || accessToken?.expires?.isBefore(Instant.now())) &&
        (!masqAccessToken || masqAccessToken?.expires?.isBefore(Instant.now()))
    ) {
        // either access token not yet loaded, or, it is expired, AND either no masq access token loaded, or, it is expired
        const tokenAction: SetTokenAction = yield take(['access-token::set', 'masq::access-token::set']);
        if (tokenAction.type === 'access-token::set') {
            accessToken = tokenAction.token;
        } else {
            masqAccessToken = tokenAction.token;
        }
    }
}
