import type { AppAction } from '../actions';
import type { ModalInstance } from '../types';

export interface OverlayState {
    activeModal: null | ModalInstance;
    modalTitle: null | string;
}

export const initialState: OverlayState = {
    activeModal: null,
    modalTitle: null,
};

export const overlay = (state = initialState, action: AppAction): OverlayState => {
    switch (action.type) {
        case 'open-modal': {
            return { ...state, activeModal: action.modal };
        }
        case 'close-modal': {
            return { ...state, activeModal: null };
        }

        default:
            return state;
    }
};
