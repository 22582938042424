import type { AppAction } from '../../../actions';
import type { WidgetTabViewModel } from '../../../types';
import { keyArrayToRecord } from '../../../utils';
import { tabIdReducer } from './[tabId]';

export type ByIdState = Record<string, WidgetTabViewModel>;

export const initialState: ByIdState = {};

export const byId = (state = initialState, action: AppAction): ByIdState => {
    switch (action.type) {
        case 'initializeWorkspace': {
            return {
                ...state,
                ...keyArrayToRecord(action.tabs, (tab) => tab.id),
            };
        }

        case 'addContainer': {
            return {
                ...state,
                [action.initialTab.id]: action.initialTab,
            };
        }

        case 'removeContainer': {
            const updatedTabsByKey: Record<string, WidgetTabViewModel> = { ...state };
            action.container.tabIds.forEach((tabId) => {
                delete updatedTabsByKey[tabId];
            });

            return {
                ...updatedTabsByKey,
            };
        }

        case 'addTab': {
            const currentlyActiveForThisContainer = Object.values(state).find(
                (x) => x.containerId === action.containerId && x.isActive,
            )?.id;

            return {
                ...state,
                ...(currentlyActiveForThisContainer
                    ? {
                          [currentlyActiveForThisContainer]: {
                              ...(state[currentlyActiveForThisContainer] ?? {}),
                              isActive: false,
                          },
                      }
                    : {}),
                [action.tab.id]: {
                    ...action.tab,
                    isActive: true,
                },
            };
        }

        case 'removeTab': {
            const updatedTabsByKey: Record<string, WidgetTabViewModel> = { ...state };
            delete updatedTabsByKey[action.tabId];

            const newActivetabId = action.newActiveTabId;
            const newActiveTab = updatedTabsByKey[newActivetabId];

            return {
                ...updatedTabsByKey,
                [newActivetabId]: {
                    ...(newActiveTab ?? {}),
                    isActive: true,
                },
            };
        }

        case 'updateActiveTab': {
            if (!state[action.tabId]) return state;

            const currentlyActiveForThisContainer = Object.values(state).find(
                (x) => x.containerId === action.containerId && x.isActive,
            );

            if (currentlyActiveForThisContainer?.id === action.tabId) return state;

            const unsetCurrentlyActiveConainer = currentlyActiveForThisContainer
                ? { [currentlyActiveForThisContainer.id]: { ...currentlyActiveForThisContainer, isActive: false } }
                : {};

            const thisTabBecomesActive = { [action.tabId]: { ...state[action.tabId], isActive: true } };

            const updatedTabs = { ...unsetCurrentlyActiveConainer, ...thisTabBecomesActive };

            return {
                ...state,
                ...updatedTabs,
            };
        }

        default:
            let newState = false;
            const nextState = { ...state };
            Object.entries(state).forEach(([key, value]) => {
                const updatedTab = tabIdReducer(value, action);
                if (updatedTab !== value) {
                    nextState[key] = updatedTab;
                    newState = true;
                }
            });

            return newState ? nextState : state;
    }
};
