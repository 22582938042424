import type { AppAction } from '../actions';
import { keyArrayToRecord } from '../utils';
import type { ConcreteUniverse } from 'src/contracts/universe';

export interface UniverseByIdState {
    universeById: Record<string, ConcreteUniverse>;
}

export const initialState: UniverseByIdState = {
    universeById: {},
};

export const universe = (state = initialState, action: AppAction): UniverseByIdState => {
    switch (action.type) {
        case 'initializeUniverses': {
            return {
                ...state,
                universeById: {
                    ...state.universeById,
                    ...keyArrayToRecord(action.universes, (universe) => universe.id),
                },
            };
        }
        case 'setUniverse': {
            return { ...state, universeById: { ...state.universeById, [action.universe.id]: action.universe } };
        }
        case 'addTagToWatchList':
        case 'addTagToUniverse': {
            if (!action.universeId) return state;
            return {
                ...state,
                universeById: {
                    ...state.universeById,
                    [action.universeId]: {
                        ...state.universeById[action.universeId],
                        inclusionList: [...state.universeById[action.universeId]?.inclusionList, action.tag],
                    },
                },
            };
        }
        case 'removeTagFromWatchList':
        case 'removeTagFromUniverse': {
            if (!action.universeId) return state;
            return {
                ...state,
                universeById: {
                    ...state.universeById,
                    [action.universeId]: {
                        ...state.universeById[action.universeId],
                        inclusionList: state.universeById[action.universeId]?.inclusionList.filter(
                            (t) => t !== action.tag,
                        ),
                    },
                },
            };
        }
        case 'userPublishedTicker': {
            return state; // todo
        }
        default: {
            return state;
        }
    }
};
