import { container as staticContainer } from './StaticContainer';
import type { ReactBindings } from './types';
import { AlphaFilterModel } from 'src/features/alpha-filter/models/AlphaFilterModel';
import { CodeEditorFeatureModule } from 'src/features/code-editor/module';
import type { TypedContainer } from 'src/features/ioc/TypedContainer';
import { NLPEditorFeatureModule } from 'src/features/nlp/module';
import { CodeDocumentModel } from 'src/models/CodeDocument';
import { CurrentUserModel } from 'src/models/CurrentUserModel';
import { ImportsManagerModel } from 'src/models/ImportsManager';
import { WidgetDataModel } from 'src/models/WidgetDataModel';
import { ConfigServiceImpl } from 'src/services/ConfigServiceImpl';
import { FormulaServiceImpl } from 'src/services/FormulaServiceImpl';
import { LoginScreenServiceImpl } from 'src/services/LoginScreenServiceImpl';
import { OAuth2ServiceImpl } from 'src/services/OAuth2ServiceImpl';
import { SecurityMasterServiceImpl } from 'src/services/SecurityMasterServiceImpl';
import { SyncInternalConfigurationsServiceImpl } from 'src/services/SyncInternalConfigurationsServiceImpl';
import { SyncToExternalsServiceImpl } from 'src/services/SyncToExternalsServiceImpl';

export type ReactContainer = TypedContainer<ReactBindings>;

/**
 * Creates the inversify container used by the client and binds all the services.
 *
 * Note that most services you bind here also need to be bound in the electron main container.
 * @see /projects/web/main/ioc/initMainContainer.ts
 */
export const initReactContainer = (): ReactContainer => {
    const container = staticContainer.createChild<ReactBindings>();

    /*
     * Modules and values
     */
    container.bind('StoreProvider').toProvider<ReactBindings['Store']>((context) => () => {
        return Promise.resolve((context.container as any as ReactContainer).get('Store'));
    });
    container.load(CodeEditorFeatureModule);
    container.load(NLPEditorFeatureModule);

    /*
     * Services
     */
    container.bind('ConfigService').to(ConfigServiceImpl).inSingletonScope();
    container.bind('FormulaService').to(FormulaServiceImpl).inSingletonScope();
    container.bind('SecurityMasterService').to(SecurityMasterServiceImpl).inSingletonScope();
    container.bind('OAuth2Service').to(OAuth2ServiceImpl).inSingletonScope();
    container.bind('LoginScreenService').to(LoginScreenServiceImpl).inSingletonScope();
    container.bind('SyncToExternalsService').to(SyncToExternalsServiceImpl).inSingletonScope();
    container.bind('SyncInternalConfigurationsService').to(SyncInternalConfigurationsServiceImpl).inSingletonScope();

    /*
     * Models
     */
    container.bind('CodeDocumentModel').to(CodeDocumentModel);
    container.bind('CurrentUserModel').to(CurrentUserModel).inSingletonScope();
    container.bind('WidgetDataModel').to(WidgetDataModel);
    container.bind('ImportsManagerModel').to(ImportsManagerModel);
    container.bind('AlphaFilterModel').to(AlphaFilterModel);

    return container;
};
