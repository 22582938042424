import type { TimeSeriesWidgetModel } from 'src/contracts/workspace';
import type { TimeSeriesActions } from 'src/store/actions/widgets/timeSeries';
import type { WidgetTabViewModel } from 'src/store/types';

export const timeSeriesReducer = (
    state: WidgetTabViewModel<TimeSeriesWidgetModel>,
    action: TimeSeriesActions,
): WidgetTabViewModel<TimeSeriesWidgetModel> => {
    switch (action.type) {
        case 'updateTimeSeriesSymbol': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbol: action.symbol,
                },
            };
        }

        default:
            return state;
    }
};
