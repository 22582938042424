import {
    APPLIED_FILTER_CHANGE_EVENT,
    type AppliedFilterChangeEvent,
    type AlphaFilterAppliedFilterType,
} from 'src/ioc/types/AlphaFilterModel';

export class AppliedFilterModel implements AlphaFilterAppliedFilterType {
    changeTarget = new EventTarget();

    #state: AlphaFilterAppliedFilterType;

    constructor(filter: AlphaFilterAppliedFilterType) {
        this.#state = filter;
    }

    #determineStatus() {
        if (this.#state.status === 'paused') {
            return 'paused';
        }

        // if any of the values are set then the filter is active
        if (
            this.#state.minValue ||
            this.#state.minValue === 0 ||
            this.#state.maxValue ||
            this.#state.maxValue === 0 ||
            this.#state.periodValue ||
            this.#state.durationValue ||
            this.#state.durationValue === 0
        ) {
            return 'active';
        }

        return 'blank';
    }

    get id() {
        return this.#state.id;
    }

    get name() {
        return this.#state.name;
    }

    get filterId() {
        return this.#state.filterId;
    }

    set state(newState: AlphaFilterAppliedFilterType) {
        this.#state = newState;
        this.status = this.#determineStatus();
        this.changeTarget.dispatchEvent(
            new CustomEvent(APPLIED_FILTER_CHANGE_EVENT) satisfies AppliedFilterChangeEvent,
        );
    }

    get status() {
        return this.#state.status;
    }

    set status(status: 'active' | 'blank' | 'paused') {
        this.#state.status = status;
    }

    get minValue() {
        return this.#state.minValue;
    }

    set minValue(minValue: number | string) {
        if (minValue === '') {
            this.state = { ...this.#state, minValue };
            return;
        }

        const minValueAsNumber = Number(minValue);
        if (isNaN(minValueAsNumber)) {
            return;
        }
        this.state = { ...this.#state, minValue };
    }

    get maxValue() {
        return this.#state.maxValue;
    }

    set maxValue(maxValue: number | string) {
        if (maxValue === '') {
            this.state = { ...this.#state, maxValue };
            return;
        }

        const maxValueAsNumber = Number(maxValue);
        if (isNaN(maxValueAsNumber)) {
            return;
        }

        this.state = { ...this.#state, maxValue };
    }

    get periodValue() {
        return this.#state.periodValue;
    }

    set periodValue(periodValue: string) {
        this.#state.periodValue = periodValue;
    }

    get durationValue() {
        return this.#state.durationValue;
    }

    set durationValue(durationValue: string | number) {
        if (durationValue === '') {
            this.state = { ...this.#state, durationValue };
            return;
        }

        const durationValueAsNumber = Number(durationValue);
        if (isNaN(durationValueAsNumber)) {
            return;
        }

        this.state = { ...this.#state, durationValue };
    }

    get periodOptions() {
        return this.#state.periodOptions;
    }

    set periodOptions(periodOptions: { name: string; value: string }[]) {
        this.state = { ...this.#state, periodOptions };
    }
}
