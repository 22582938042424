import type { NlpSuggestion } from 'src/lib/nlp/contracts';
import type { AppAction } from 'src/store/actions';

interface AskAlphaState {
    commonSuggestions: NlpSuggestion[];
}

export const initialState: AskAlphaState = {
    commonSuggestions: [],
};

export const askAlpha = (state = initialState, action: AppAction): AskAlphaState => {
    switch (action.type) {
        case 'askAlpha::setCommonSuggestions': {
            return { ...state, commonSuggestions: action.suggestions };
        }

        default:
            return state;
    }
};
