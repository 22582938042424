import type { ExecutionsActions } from 'src/store/actions/widgets/executions';
import type { ExecutionsWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const executionsReducer = (
    state: WidgetTabViewModel<ExecutionsWidgetViewModel>,
    action: ExecutionsActions,
): WidgetTabViewModel<ExecutionsWidgetViewModel> => {
    switch (action.type) {
        case 'updateExecutionsAccount': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    accountIds: action.accountId ? [action.accountId] : [],
                },
            };
        }
        case 'updateExecutionsSymbolFilter': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbolFilter: action.symbol,
                },
            };
        }

        default:
            return state;
    }
};
