import { byId, type ByIdState } from './byId';
import { combineReducers } from 'redux';

export interface TabState {
    byId: ByIdState;
}

export const initialState: TabState = {
    byId: {},
};

export const tab = combineReducers({
    byId,
});
