import type { WidgetViewModel } from '../types';
import type { Universe } from 'src/contracts/universe';

export type AddTagToWatchListAction = {
    type: 'addTagToWatchList';
    tag: string;
    tabId: string;
    universeId?: string;
    skipDirtyCheck?: boolean;
};
export const addTagToWatchList = (payload: Omit<AddTagToWatchListAction, 'type'>): AddTagToWatchListAction => ({
    type: 'addTagToWatchList',
    ...payload,
});

export type RemoveTagFromWatchListAction = {
    type: 'removeTagFromWatchList';
    tag: string;
    tabId: string;
    universeId?: string;
    skipDirtyCheck?: boolean;
};
export const removeTagFromWatchList = (
    payload: Omit<RemoveTagFromWatchListAction, 'type'>,
): RemoveTagFromWatchListAction => ({
    type: 'removeTagFromWatchList',
    ...payload,
});

export type ChangeSelectedUniverseAction = {
    type: 'changeSelectedUniverse';
    tabId: string;
    universe: Universe | string;
};
export const changeSelectedUniverse = (
    payload: Omit<ChangeSelectedUniverseAction, 'type'>,
): ChangeSelectedUniverseAction => ({
    type: 'changeSelectedUniverse',
    ...payload,
});

export type SetSelectedUniverseAction = {
    type: 'setSelectedUniverse';
    tabId: string;
    universe: Universe | string;
    skipDirtyCheck?: boolean;
};
export const setSelectedUniverse = (payload: Omit<SetSelectedUniverseAction, 'type'>): SetSelectedUniverseAction => ({
    type: 'setSelectedUniverse',
    ...payload,
});

export type UpdateWidgetAction = {
    type: 'updateWidget';
    tabId: string;
    widgetUpdates: Partial<WidgetViewModel>;
    tabUpdates?: Partial<{ name: string }>;
    skipDirtyCheck?: boolean; // if true, the action will not mark the workspace as dirty
};

/**
 * @deprecated Use specific actions instead
 */
export const updateWidget = (payload: Omit<UpdateWidgetAction, 'type'>): UpdateWidgetAction => ({
    type: 'updateWidget',
    ...payload,
});

export type OnTabChannelChangeEvent = {
    type: 'onTabChannelChange';
    tabId: string;
    channelId: string | null;
};

export const onTabChannelChange = (tabId: string, channelId: string | null): OnTabChannelChangeEvent => ({
    type: 'onTabChannelChange',
    tabId,
    channelId,
});

export type ResetChannelDataAction = {
    type: 'resetChannelData';
    tabId: string;
};

export const resetChannelData = (tabId: string): ResetChannelDataAction => ({
    type: 'resetChannelData',
    tabId,
});

export type WidgetAndChannelAction =
    | ResetChannelDataAction
    | RemoveTagFromWatchListAction
    | AddTagToWatchListAction
    | ChangeSelectedUniverseAction
    | SetSelectedUniverseAction
    | UpdateWidgetAction
    | OnTabChannelChangeEvent;
