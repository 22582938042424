import { waitForAuth } from '../auth/helpers';
import { all, getContext, takeLeading, call } from 'redux-saga/effects';
import { firstValueFrom } from 'rxjs';
import { getIndicators } from 'src/lib/dictionary/dictionary';
import { getTopStrategyRecords } from 'src/lib/strategies';
import type { SagaContext } from 'src/store/types';

export function* prefillIndicatorList() {
    try {
        yield* waitForAuth();
        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');

        const fetchUserQuery = () =>
            queryClient.fetchUserQuery({
                queryKey: ['indicators'],
                queryFn: () => getIndicators(),
            });

        yield call(fetchUserQuery);
    } catch (e) {}
}

export function* prefillTopStrategies() {
    try {
        yield* waitForAuth();

        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');

        const fetchUserQuery = () =>
            queryClient.fetchUserQuery({
                queryKey: ['strategies'],
                queryFn: () => firstValueFrom(getTopStrategyRecords()),
            });
        yield call(fetchUserQuery);
    } catch (e) {}
}

export function* queryClientSagas() {
    yield all([
        takeLeading(
            ['masq::access-token::set', 'masq::access-token::clear', 'superuser::set-expiration', 'access-token::set'],
            prefillIndicatorList,
        ),
        takeLeading(
            ['masq::access-token::set', 'masq::access-token::clear', 'superuser::set-expiration', 'access-token::set'],
            prefillTopStrategies,
        ),
    ]);
}
