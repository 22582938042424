import type { Selector } from 'react-redux';
import type { StoreEnhancer, Unsubscribe, Store, AnyAction } from 'redux';

export interface SubscribeSelectorOptions {
    /**
     * If true, the selector will be called immediately after subscribing.
     */
    leading?: boolean;
}

export type SubscribeSelector<T> = {
    handle(callback: (value: T) => void): Unsubscribe;
};

export type WithSubscribeSelector<U extends Store<any, AnyAction>> =
    U extends Store<infer S>
        ? U & {
              subscribeSelector<T>(selector: Selector<S, T>, options?: SubscribeSelectorOptions): SubscribeSelector<T>;
          }
        : never;

export const createSubscribeSelector = <R>(store: Store<R, AnyAction>) => {
    return <T>(selector: Selector<R, T>, options: SubscribeSelectorOptions = {}): SubscribeSelector<T> => {
        return {
            handle: (onChange) => {
                let currentValue: any;

                const handleChange = () => {
                    const nextValue = selector(store.getState());

                    if (nextValue !== currentValue) {
                        currentValue = nextValue;
                        onChange(nextValue);
                    }
                };

                const unsubscribe = store.subscribe(handleChange);

                if (options?.leading) {
                    handleChange();
                }

                return unsubscribe;
            },
        };
    };
};

export const addSubscribeSelector: StoreEnhancer = (createStore) => {
    return (reducer, initialState) => {
        const store = createStore(reducer, initialState);

        return {
            ...store,
            subscribeSelector: createSubscribeSelector(store),
        };
    };
};
