import type {
    FetchSecurityMasterEntriesResponse,
    FetchSymbolsMatchingPrefixResponse,
    SecurityMasterQueryMasterResponse,
    SecurityMasterServiceRequest,
    SecurityMasterServiceResponse,
} from './types';
import { type LocalDate } from '@js-joda/core';
import { injectable } from 'inversify';
import type { SecurityMasterService } from 'src/ioc/types/SecurityMasterService';
import { v4 as uuid } from 'uuid';

@injectable()
export class SecurityMasterServiceImpl implements SecurityMasterService {
    #worker: Worker;

    constructor() {
        this.#worker = new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' });
    }

    async #sendWorkerRequest<TResult>(request: SecurityMasterServiceRequest): Promise<TResult> {
        this.#worker.postMessage(request);

        return new Promise((resolve) => {
            const listener = (e: MessageEvent<SecurityMasterServiceResponse>) => {
                if (e.data.id === request.id) {
                    this.#worker.removeEventListener('message', listener);
                    resolve(e.data as TResult);
                }
            };

            this.#worker.addEventListener('message', listener);
        });
    }

    async fetchSecurityMasterEntry(symbol: string) {
        // ToDo: Implement https://github.com/yornaath/batshit so we can useQueries on fetchSecurityMasterEntry
        const results = await this.#sendWorkerRequest<FetchSecurityMasterEntriesResponse>({
            type: 'fetch-security-master-entries',
            symbols: [symbol],
            id: uuid(),
        });

        const result = results.entries[symbol];

        if (!result) {
            throw new Error('Symbol not found in security master');
        }

        return result;
    }

    async fetchSecurityMasterEntries(symbols: string[]) {
        // ToDo: Implement https://github.com/yornaath/batshit so we can useQueries on fetchSecurityMasterEntry
        return await this.#sendWorkerRequest<FetchSecurityMasterEntriesResponse>({
            type: 'fetch-security-master-entries',
            symbols: symbols,
            id: uuid(),
        }).then((res) => res.entries);
    }

    async fetchSecurityMaster(accessToken: string, forDate: LocalDate, features: string[]) {
        return await this.#sendWorkerRequest<SecurityMasterQueryMasterResponse>({
            type: 'query-master',
            accessToken,
            features,
            forDate,
            id: uuid(),
        }).then(() => 'SECURITY_MASTER');
    }

    async fetchSymbolsMatchingPrefix(prefix: string) {
        const response = await this.#sendWorkerRequest<FetchSymbolsMatchingPrefixResponse>({
            type: 'fetch-symbol-list',
            id: uuid(),
            prefix,
        });

        return response.matchingSymbols;
    }
}
