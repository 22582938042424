import z from 'zod';

/**
 * Position directly on the canvas/screen, using a top-left xy offset
 */
export const AbsolutePositionSchema = z.object({
    type: z.literal('absolute'),
    x: z.number(),
    y: z.number(),
});
export type AbsolutePosition = z.infer<typeof AbsolutePositionSchema>;

/**
 * Position off-screen (hidden)
 */
export const OffScreenPositionSchema = z.object({
    type: z.literal('offScreen'),
});
export type OffScreenPosition = z.infer<typeof OffScreenPositionSchema>;

/**
 * Position relative to a window element, given the bounding box for the element in that window
 */
export const WindowElementRelativePositionSchema = z.object({
    type: z.literal('windowElementRelative'),
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
});
export type WindowElementRelativePosition = z.infer<typeof WindowElementRelativePositionSchema>;

/**
 * Position relative to the window, using a top-left xy offset
 */
export const WindowRelativePositionSchema = z.object({
    type: z.literal('windowRelative'),
    x: z.number(),
    y: z.number(),
});
export type WindowRelativePosition = z.infer<typeof WindowRelativePositionSchema>;

export const PositionSchema = z.discriminatedUnion('type', [
    AbsolutePositionSchema,
    OffScreenPositionSchema,
    WindowElementRelativePositionSchema,
    WindowRelativePositionSchema,
]);
export type Position = z.infer<typeof PositionSchema>;
