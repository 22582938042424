import type { AppAction } from '../actions';
import type { FeatureFlagSet } from '../types';

export interface FeatureFlagState {
    flags: FeatureFlagSet;
}

export const initialState: FeatureFlagState = {
    flags: {
        constrainWidgetsToDashboardBounds: false,
        enableLogTables: false,
        enableKeyboardCommands: false,
        showRedesignedColumnTemplateEditor: false,
        contextMenuColumnFormatting: false,
        enableNewLibrary: false,
        disableSessionBarrier: false,
        enableOwnerInvitation: false,
        enableThemeSwitching: false,
        enableNewCodeEditor: false,
        enableSimpleOrderEntry: false,
        systemMessage: '',
        newsChatbot: false,
        showAlphaFilterModal: false,
        useAlphaLensSpeedupQuery: false,
        enableSearchAlphaWalkthrough: false,
        enableLabNewForms: false,
        enableNewAdminSidebar: false,
    },
};

export const featureFlags = (state = initialState, action: AppAction): FeatureFlagState => {
    switch (action.type) {
        case 'featureFlags::set': {
            return { ...state, flags: { ...state.flags, ...action.flags } };
        }

        default:
            return state;
    }
};
