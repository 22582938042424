import type z from 'zod';

export const REMOTE_SLICE_STORE_ADDRESS = 'remoteSlice' as const;
export const REMOTE_SLICE_ACTION = 'REMOTE_SLICE_ACTION' as const;
export const REMOTE_SLICE_CHANNEL = 'REMOTE_SLICE_CHANNEL' as const;

/**
 * Action type which is shared between slice actions and channel messages
 */
export type RemoteSliceAction<T = any> = {
    type: `${typeof REMOTE_SLICE_ACTION}:${string}:${string}` | `${typeof REMOTE_SLICE_CHANNEL}:${string}:${string}`;
    payload: T;
};

/**
 * Given type of a slice config, get the types of the actions
 */
export type RemoteSliceActions<C extends RemoteSliceConfig<any, any, any>> =
    C extends RemoteSliceConfig<any, infer AS, any>
        ? {
              [key in keyof AS]: AS[key] extends z.ZodTypeAny ? RemoteSliceAction<z.infer<AS[key]>> : never;
          }
        : never;

/*
 * How to define a remote slice
 */
export type Actions<T extends keyof any = string> = Record<T, z.ZodTypeAny>;

export type Channels<T extends keyof any = string> = Record<T, z.ZodTypeAny>;

export interface RemoteSliceConfigOptions<Z extends z.ZodTypeAny, AS extends Actions, CS extends Channels> {
    name: string;
    state: Z;
    actions?: AS;
    channels?: CS;
}

export type RemoteSliceActionTypes<AS extends Actions> = {
    [key in keyof AS]: AS[key] extends z.ZodTypeAny ? `${typeof REMOTE_SLICE_ACTION}:${string}:${string}` : never;
};

export interface RemoteSliceConfig<Z extends z.ZodTypeAny, AS extends Actions, CS extends Channels> {
    name: string;
    state: Z;
    actions?: AS;
    channels?: CS;
    actionTypes: RemoteSliceActionTypes<AS>;
}

/**
 * Utility type to extract the action types from a slice config
 */
type ActionTypes<T extends Actions> = {
    [key in keyof T]: T[key] extends z.ZodTypeAny ? RemoteSliceAction<z.infer<T[key]>> : never;
}[keyof T];

/**
 * Utility type to define reducers according to slice action types
 */
type RemoteSliceReducerDefinitions<S, AS extends Actions> = {
    [key in keyof AS]: AS[key] extends z.ZodTypeAny
        ? (state: S, action: RemoteSliceAction<z.infer<AS[key]>>) => S
        : never;
};

/**
 * Create a remote slice
 */
export interface CreateRemoteSliceOptions<Z extends z.ZodTypeAny, AS extends Actions, CS extends Channels> {
    config: RemoteSliceConfig<Z, AS, CS>;
    initialState: z.infer<Z>;
    reducers: RemoteSliceReducerDefinitions<z.infer<Z>, AS>;
}

export type RemoteSlice<S, AS extends Actions> = {
    name: string;
    reducer: (state: S | undefined, action: ActionTypes<AS>) => S;
};

/**
 * Utility to create a remote slice config
 */
export const createRemoteSliceConfig = <Z extends z.ZodTypeAny, AS extends Actions, CS extends Channels>(
    config: RemoteSliceConfigOptions<Z, AS, CS>,
): RemoteSliceConfig<Z, AS, CS> => {
    return {
        ...config,
        actionTypes: Object.fromEntries(
            Object.entries(config.actions ?? {}).map(([key, _schema]) => [
                key,
                `${REMOTE_SLICE_ACTION}:${config.name}:${key}`,
            ]),
        ) as any as RemoteSliceActionTypes<AS>,
    };
};

/**
 *
 */
export const createRemoteSlice = <Z extends z.ZodTypeAny, AS extends Actions, CS extends Channels>({
    config,
    initialState,
    reducers,
}: CreateRemoteSliceOptions<Z, AS, CS>): RemoteSlice<z.infer<Z>, AS> => {
    type Return = RemoteSlice<z.infer<Z>, AS>;

    const reducer: Return['reducer'] = (state = initialState, action) => {
        if (typeof action.type !== 'string') return state;

        if (!(action.type as string).startsWith(`${REMOTE_SLICE_ACTION}:${config.name}:`)) return state;

        const [_, actionName] = action.type.split(`${REMOTE_SLICE_ACTION}:${config.name}:`);

        if (reducers[actionName]) {
            return reducers[actionName]?.(state, action as any);
        }
        return state;
    };

    return {
        name: config.name,
        reducer,
    };
};

export const createRemoteSliceAction = <AS extends Actions, T extends keyof AS>(
    config: RemoteSliceConfig<any, AS, any>,
    name: T,
    payload?: z.infer<AS[T]>,
): RemoteSliceAction<z.infer<AS[T]>> => {
    return {
        type: `${REMOTE_SLICE_ACTION}:${config.name}:${String(name)}`,
        payload,
    };
};

export const createRemoteSliceChannelMessage = <CS extends Channels, T extends keyof CS>(
    config: RemoteSliceConfig<any, any, CS>,
    name: T,
    payload: z.infer<CS[T]>,
): RemoteSliceAction<z.infer<CS[T]>> => {
    return {
        type: `${REMOTE_SLICE_CHANNEL}:${config.name}:${String(name)}`,
        payload,
    };
};

/**
 * Utility to get just actions types for a remote slice
 */
