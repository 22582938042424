import type { LocatesWidgetModel } from 'src/contracts/workspace';
import type { LocatesActions } from 'src/store/actions/widgets/locates';
import type { WidgetTabViewModel } from 'src/store/types';

export const locatesReducer = (
    state: WidgetTabViewModel<LocatesWidgetModel>,
    action: LocatesActions,
): WidgetTabViewModel<LocatesWidgetModel> => {
    switch (action.type) {
        case 'updateLocatesAccount': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    accountIds: action.accountId ? [action.accountId] : [],
                },
            };
        }
        case 'updateLocatesSymbol': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    formData: {
                        ...state.widget.formData,
                        symbol: action.symbol,
                    },
                },
            };
        }

        default:
            return state;
    }
};
