import { createStructuredSelector } from 'reselect';
import { ReactiveInjectable, reacts, injectable, inject } from 'src/features/ioc';
import type { ReactBindings } from 'src/ioc/types';
import type { CurrentUserModelType } from 'src/ioc/types/CurrentUserModel';
import type { RootState } from 'src/store';
import { clearMasq } from 'src/store/actions/auth';

const selectUserWithMasq = createStructuredSelector({
    user: (state: RootState) => state.auth.user,
    masqUser: (state: RootState) => state.auth.masqUser,
    isSuperUserModeEnabled: (state: RootState) => Boolean(state.ui.superuserAccessEnabledUntil),
    masqExpires: (state: RootState) => state.auth.masqAccessToken?.expires,
});

type UserData = ReturnType<typeof selectUserWithMasq>;

@injectable()
export class CurrentUserModel extends ReactiveInjectable implements CurrentUserModelType {
    #userData: UserData;

    constructor(@inject('Store') private store: ReactBindings['Store']) {
        super();

        this.#userData = selectUserWithMasq(this.store.getState());
        store.subscribeSelector(selectUserWithMasq).handle((userData) => (this.userData = userData));
    }

    @reacts set userData(userData: UserData) {
        this.#userData = userData;
    }

    get userName() {
        return this.#userData.masqUser !== undefined
            ? `${this.#userData.masqUser.firstName} ${this.#userData.masqUser.lastName}`
            : `${this.#userData.user?.firstName} ${this.#userData.user?.lastName}`;
    }

    get userEmail() {
        return this.#userData.masqUser?.email ?? this.#userData.user?.email;
    }

    get isMasquerading() {
        return this.#userData.masqUser !== undefined;
    }

    get isSuperuser() {
        return this.#userData.user?.isSuperuser ?? false;
    }

    get isSuperUserModeEnabled() {
        return this.#userData.isSuperUserModeEnabled;
    }

    get masqExpires() {
        return this.#userData.masqExpires;
    }

    toggleSuperUserMode(enabled: boolean) {
        this.store.dispatch({
            type: 'superuser::toggle',
            adminModeEnabled: enabled,
        });
    }

    stopMasq() {
        this.store.dispatch(clearMasq());
    }
}
