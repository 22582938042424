import { FieldTypeCategory, renderValue, type ConcreteDataType } from '@thinkalpha/language-services';
import type { AllowedArgumentViewModel, IndicatorParameterViewModel } from 'src/contracts/dictionary-view-model';
import z from 'zod';

export const makeOptionsSchema = (options: ParamOptions[]) => {
    const stringValues = options.map((opt) => opt.input);
    const schema = z.string().refine((value) => stringValues.includes(value), {
        message: `Value must be one of: ${stringValues.join(', ')}`,
    });
    return schema;
};

interface ParamOptions {
    input: string;
    value: AllowedArgumentViewModel;
}

export class IndicatorImportParamModel {
    #param: IndicatorParameterViewModel;

    #options: ParamOptions[];

    #schema: z.ZodSchema<any>;

    constructor(param: IndicatorParameterViewModel) {
        this.#param = param;

        this.#options = (param.options?.values || []).map((param) => {
            let input: string;
            if (typeof param === 'string') input = `${param}`;
            else if (typeof param === 'boolean') input = `${param}`;
            else if (typeof param === 'number') input = param.toString();
            // TODO: Handle case of EnumeratedValues
            else if (typeof param === 'object' && param && 'input' in param) input = `${param.input}`;
            else input = renderValue(param);

            return {
                input,
                value: param,
            };
        });

        if (param.dataType === FieldTypeCategory.Periodicity || this.#options.length > 0) {
            this.#schema = makeOptionsSchema(this.#options);
        } else {
            this.#schema = z.string();
        }
    }

    get name(): string | undefined {
        return this.#param.name;
    }

    get optional(): boolean {
        return this.#param.optional;
    }

    get type(): ConcreteDataType {
        return this.#param.dataType;
    }

    get options(): ParamOptions[] {
        return this.#options;
    }

    get schema() {
        return this.#schema;
    }
}
