import { Instant } from '@js-joda/core';
import type { JwtPayload } from 'jwt-decode';
import jd from 'jwt-decode';

export class OAuth2CodeWrongOrExpiredError extends Error {
    constructor(description: string) {
        super(description);
    }
}

export interface AccessTokenPayload extends JwtPayload {
    isSuperuser: boolean;
    type: string;
    userId: string;
    isDeveloper: boolean;
    email: string;
}

export function decodeToken(token: string): AccessTokenPayload {
    const payload = jd<AccessTokenPayload>(token);
    return payload;
}

export function getExpirationDate(token: string): Instant | undefined {
    const payload = decodeToken(token);
    if (typeof payload.exp === 'number' && typeof payload.iat === 'number') {
        return Instant.ofEpochSecond(payload.exp);
    }
    return undefined;
}
