import type {
    IndicatorFormulaViewModel,
    IndicatorImportViewModel,
    IndicatorImportRefViewModel,
} from 'src/contracts/dictionary-view-model';
import type { IfThenResearchPlan } from 'src/contracts/if-then';
import type { ConcreteStrategy, IfThenStrategy, Strategy } from 'src/contracts/strategy';

export type ResearchStrategy<T extends IndicatorImportRefViewModel = IndicatorImportRefViewModel> = IfThenStrategy<
    T,
    IfThenResearchPlan<'ticker', T>
>;

export function isStrategy(strategyLike: Strategy | string | string[]): strategyLike is Strategy {
    return typeof strategyLike === 'object' && 'type' in strategyLike;
}

export function isConcreteStrategy(
    strategy: Strategy<IndicatorImportViewModel> | ConcreteStrategy<IndicatorImportViewModel>,
): strategy is ConcreteStrategy<IndicatorImportViewModel>;
export function isConcreteStrategy(strategy: Strategy | ConcreteStrategy): strategy is ConcreteStrategy;
export function isConcreteStrategy(strategy: Strategy | ConcreteStrategy): strategy is ConcreteStrategy {
    return !!(strategy as ConcreteStrategy).id;
}

export function isResearchStrategy<T extends IndicatorImportRefViewModel>(
    strategy: Strategy<T>,
): strategy is ResearchStrategy<T> {
    return true; // change this if there becomes another type of strategy
}

export function isIfThenResearchPlan<TKey extends string, T extends IndicatorImportRefViewModel>(
    plan: IfThenResearchPlan<TKey, T> | IndicatorFormulaViewModel<T>,
): plan is IfThenResearchPlan<TKey, T> {
    return !!(plan as IfThenResearchPlan<TKey, T>).root;
}
