import { type ResultsWidgetActions } from 'src/store/actions/widgets/results';
import type { StrategyWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const resultsReducer = (
    state: WidgetTabViewModel<StrategyWidgetViewModel>,
    action: ResultsWidgetActions,
): WidgetTabViewModel<StrategyWidgetViewModel> => {
    switch (action.type) {
        case 'setResultsStrategy': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    strategies: [action.strategy],
                },
            };
        }

        default:
            return state;
    }
};
