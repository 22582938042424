import { Duration, Instant } from '@js-joda/core';
import type { CallEffect } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';

/**
 * Returns an effect descriptor to block execution for `duration` and return `val` value.
 */
export function delayDuration<T = true>(duration: Duration, val?: T): CallEffect<T> {
    return delay(Math.max(0, duration.toMillis()), val);
}

/**
 * Returns an effect descriptor to block execution until `dueTime` and return `val` value.
 */
export function delayUntil<T = true>(dueTime: Instant, val?: T): CallEffect<T> {
    return delayDuration<T>(Duration.between(Instant.now(), dueTime), val);
}
