import { z } from 'zod';

export enum NlpQueryType {
    operand = 'operand',
    condition = 'condition',
    date = 'date',
}

export enum NlpQueryParseType {
    query = 'query',
    date = 'date',
}

export const NlpSimilarRequestDataSchema = z.object({
    key: z.string().optional(),
    input: z.string(),
    isConditional: z.boolean(),
});

export const NlpSuggestionSchema = z.object({
    input: z.string(),
    formula: z.string(),
    score: z.number().optional(),
    type: z.enum(['operands', 'conditions', 'timeframes']).optional(),
});

export const NlpSimilarResponseDataSchema = z.object({
    input: z.string(),
    similar: z.array(NlpSuggestionSchema),
});

export const NlpParseRequestDataSchema = z.object({
    type: z.nativeEnum(NlpQueryParseType),
    key: z.string().optional(),
    input: z.string(),
    isConditional: z.boolean(),
    clientId: z.string().optional(),
    isDone: z.boolean().optional(),
});

const IndicatorImportRefSchema = z.object({
    alias: z.string(),
    id: z.string(),
    version: z.number(),
});

// export const NlpColumnSchema = IndicatorImportRefSchema.extend({
//     name: z.string(),
// });
export const NlpColumnSchema = z.unknown();

const FormulaResultSchema = z.object({
    formula: z.string(),
    imports: z.array(IndicatorImportRefSchema),
});

export const NlpParseResponseDataSchema = z.object({
    docId: z.string(),
    result: FormulaResultSchema,
    modified: z
        .object({
            reason: z.string(),
            from: z.string(),
            to: z.string(),
        })
        .nullable(),
    similar: z.array(NlpSuggestionSchema).optional(),
    key: z.string(),
    clientId: z.string().optional(),
    namedColumns: z.array(NlpColumnSchema),
    benchmarkFormulae: z.array(NlpColumnSchema),
});

export const NlpParseResponseErrorSchema = z.object({
    detail: z.object({
        msg: z.string().optional(),
        similar: z.array(NlpSuggestionSchema).optional(),
    }),
});

export const NlpAutocompleteRequestDataSchema = z.object({
    input: z.string(),
    key: z.string(),
    isConditional: z.boolean(),
});

export const NlpAutocompleteResponseDataSchema = z.object({
    autocomplete: z.array(z.string()),
});

export const NlpSuggestionWithMockIdSchema = NlpSuggestionSchema.extend({
    id: z.string(),
});

export const NlpExampleSchema = NlpSuggestionSchema.extend({
    type: z.enum(['operands', 'conditions', 'timeframes']).optional(),
});

export const NlpExampleResponseSchema = z.object({
    data: z.array(NlpExampleSchema),
    count: z.number(),
    total: z.number(),
});

export const StockSymbolSchema = z.object({
    name: z.string(),
    ticker: z.string(),
});

export const CompanySuggestionCategorySchema = z.object({
    category_description: z.string(),
    category_label: z.string(),
    category_name: z.string(),
    comm_ticker_count: z.number(),
    comm_tickers_list: z.array(StockSymbolSchema),
    pref_ticker_count: z.number(),
    pref_tickers_list: z.array(StockSymbolSchema),
});

export const CompanySuggestionsResponseDataSchema = z.object({
    result: z.array(CompanySuggestionCategorySchema),
});

// Type inference
export type NlpSimilarRequestData = z.infer<typeof NlpSimilarRequestDataSchema>;
export type NlpSimilarResponseData = z.infer<typeof NlpSimilarResponseDataSchema>;
export type NlpParseRequestData = z.infer<typeof NlpParseRequestDataSchema>;
export type NlpParseResponseData = z.infer<typeof NlpParseResponseDataSchema>;
export type NlpParseResponseError = z.infer<typeof NlpParseResponseErrorSchema>;
export type NlpColumn = z.infer<typeof NlpColumnSchema>;
export type NlpAutocompleteRequestData = z.infer<typeof NlpAutocompleteRequestDataSchema>;
export type NlpAutocompleteResponseData = z.infer<typeof NlpAutocompleteResponseDataSchema>;
export type NlpSuggestion = z.infer<typeof NlpSuggestionSchema>;
export type NlpSuggestionWithMockId = z.infer<typeof NlpSuggestionWithMockIdSchema>;
export type NlpExample = z.infer<typeof NlpExampleSchema>;
export type NlpExampleResponse = z.infer<typeof NlpExampleResponseSchema>;
export type StockSymbol = z.infer<typeof StockSymbolSchema>;
export type CompanySuggestionCategory = z.infer<typeof CompanySuggestionCategorySchema>;
export type CompanySuggestionsResponseData = z.infer<typeof CompanySuggestionsResponseDataSchema>;
