import type {
    AddTagToWatchListAction,
    RemoveTagFromWatchListAction,
    SetSelectedUniverseAction,
} from 'src/store/actions/widgetAndChannel';
import type { StrategyWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export type WatchlistAction = AddTagToWatchListAction | RemoveTagFromWatchListAction | SetSelectedUniverseAction;

export const watchlistReducer = (
    state: WidgetTabViewModel<StrategyWidgetViewModel>,
    action: WatchlistAction,
): WidgetTabViewModel<StrategyWidgetViewModel> => {
    switch (action.type) {
        case 'addTagToWatchList': {
            if (state.widget.universe === null || typeof state.widget.universe === 'string') {
                return state;
            }

            return {
                ...state,
                widget: {
                    ...state.widget,
                    universe: {
                        ...state.widget.universe,
                        inclusionList: [...(state.widget.universe.inclusionList ?? []), action.tag],
                    },
                },
            };
        }

        case 'removeTagFromWatchList': {
            const targetedUniverse = state.widget.universe;
            if (targetedUniverse === null || typeof targetedUniverse === 'string') {
                return state;
            }
            return {
                ...state,
                widget: {
                    ...state.widget,
                    universe: {
                        ...targetedUniverse,
                        inclusionList: targetedUniverse.inclusionList?.filter((t) => t !== action.tag),
                    },
                },
            };
        }

        case 'setSelectedUniverse': {
            return {
                ...state,
                widget: { ...state.widget, universe: action.universe },
            };
        }
        default:
            return state;
    }
};
