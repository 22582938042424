import type { OrderBlotterWidgetModel } from 'src/contracts/workspace';
import type { OrderBlotterActions } from 'src/store/actions/widgets/orderBlotter';
import type { WidgetTabViewModel } from 'src/store/types';

export const orderBlotterReducer = (
    state: WidgetTabViewModel<OrderBlotterWidgetModel>,
    action: OrderBlotterActions,
): WidgetTabViewModel<OrderBlotterWidgetModel> => {
    switch (action.type) {
        case 'updateOrderBlotterAccount': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    accountId: action.accountId,
                },
            };
        }
        case 'updateOrderBlotterSymbol': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    tickerFilter: action.symbol,
                },
            };
        }

        default:
            return state;
    }
};
