import type { RootState } from '..';
import { createSelector } from 'reselect';

/**
 * @param state the RootState
 * @returns Will always be the root user id, even if the user is masquerading.
 */
export const getRootUser = (state: RootState): RootState['auth']['user'] => {
    return state.auth.user;
};

/**
 * @param state the RootState
 * @returns Will be the masqueraded user id, if the user is masquerading.
 */
export const getUser = (state: RootState): RootState['auth']['user'] => state.auth.masqUser ?? state.auth.user;
export const getAccessTokens = (state: RootState): Pick<RootState['auth'], 'accessToken' | 'masqAccessToken'> => ({
    accessToken: state.auth.accessToken,
    masqAccessToken: state.auth.masqAccessToken,
});

export const getClientCredentials = (state: RootState): RootState['auth']['clientCredentials'] =>
    state.auth.clientCredentials;

export const getCurrentAccessToken = createSelector(getAccessTokens, (tokens) => {
    return tokens?.masqAccessToken?.token ?? tokens?.accessToken?.token;
});
