import type { OrdersActions } from 'src/store/actions/widgets/orders';
import type { OrdersWidgetViewModel, WidgetTabViewModel } from 'src/store/types';

export const ordersReducer = (
    state: WidgetTabViewModel<OrdersWidgetViewModel>,
    action: OrdersActions,
): WidgetTabViewModel<OrdersWidgetViewModel> => {
    switch (action.type) {
        case 'updateOrdersAccount': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    accountIds: action.accountId ? [action.accountId] : [],
                },
            };
        }

        case 'updateOrdersSymbolFilter': {
            return {
                ...state,
                widget: {
                    ...state.widget,
                    symbolFilter: action.symbol,
                },
            };
        }

        default:
            return state;
    }
};
