import type { StoreEnhancer, Store, AnyAction } from 'redux';

export type WithRun<U extends Store<any, AnyAction>> = U & {
    run: () => void;
};

export const addRunFunction =
    (run: () => void): StoreEnhancer =>
    (createStore) =>
    (reducer, initialState) => {
        const store = createStore(reducer, initialState);

        return {
            ...store,
            run,
        };
    };
