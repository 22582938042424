import type { IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import type { Strategy } from 'src/contracts/strategy';
import type {
    AccountsWidgetModel,
    AggregatedPositionsWidgetModel,
    ExecutionsWidgetModel,
    IfThenBuilderWidgetModel,
    Layout,
    AlphaLensWidgetModel,
    LocatesWidgetModel,
    MessagesWidgetModel,
    OrderBlotterWidgetModel,
    OrderEntryWidgetModel,
    OrdersWidgetModel,
    PositionsWidgetModel,
    StrategyWidgetModel,
    TableWidgetModel,
    TimeSeriesWidgetModel,
    WidgetContainer,
    WidgetTab,
    Workspace,
    SimpleOrderEntryWidgetModel,
    SearchAlphaWidgetModel,
    NewsAndEventsWidgetModel,
} from 'src/contracts/workspace';

export * from './sagaContext';

export type FeatureFlagSet = {
    showRedesignedColumnTemplateEditor: boolean;
    contextMenuColumnFormatting: boolean;
    enableNewLibrary: boolean;
    enableKeyboardCommands: boolean;
    constrainWidgetsToDashboardBounds: boolean;
    disableSessionBarrier: boolean;
    enableOwnerInvitation: boolean;
    enableThemeSwitching: boolean;
    enableNewCodeEditor: boolean;
    enableLogTables: boolean;
    enableSimpleOrderEntry: boolean;
    systemMessage: string;
    newsChatbot: boolean;
    showAlphaFilterModal: boolean;
    useAlphaLensSpeedupQuery: boolean;
    enableSearchAlphaWalkthrough: boolean;
    enableLabNewForms: boolean;
    enableNewAdminSidebar: boolean;
};

export type FeatureFlag = keyof FeatureFlagSet;

export interface UserDetails {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    /**
     * isSuperuser is a flag for global, unrestricted superuser permissions.
     */
    isSuperuser: boolean;
    isDeveloper: boolean;
}

export interface WorkspaceViewModel extends Omit<Workspace, 'containers' | 'channels'> {
    containerIds: string[];
    activeContainerId?: string;
    isDirty: boolean;
    isDefaultWorkspace: boolean;
    activeBorderColor: string;
}

export interface ContainerViewModel extends Omit<WidgetContainer, 'tabs' | 'layout'> {
    tabIds: string[];
}

export interface ContainerLayoutViewModel extends Layout {
    containerId: string;
}

/**
 * WidgetViewModel is going to be the UI-relevant data for each widget type
 * There is a divergence between what is "needed" on the UI
 * and what is "needed" on the server, and often we need to map between them
 * For example, take the StrategyWidgetViewModel -- it's almost a StrategyWidgetModel.
 * The server really doesn't care about the full imports on the strategy,
 * but on the UI side we want all of the imports expanded so that we can
 * ascertain the data type of each validation column from the strategy
 */

export interface StrategyWidgetViewModel extends StrategyWidgetModel {
    strategies?: (Strategy<IndicatorImportViewModel> | string)[];
}

export interface MessagesWidgetViewModel extends MessagesWidgetModel {
    symbolFilter: string;
}

export interface PositionsWidgetViewModel extends PositionsWidgetModel {
    symbolFilter: string;
}
export interface OrdersWidgetViewModel extends OrdersWidgetModel {
    symbolFilter: string;
}

export interface ExecutionsWidgetViewModel extends ExecutionsWidgetModel {
    symbolFilter: string;
}

export interface OrdersWidgetViewModel extends OrdersWidgetModel {
    symbolFilter: string;
}

export interface NewsAndEventsWidgetViewModel extends NewsAndEventsWidgetModel {}
export interface IfThenBuilderWidgetViewModel extends IfThenBuilderWidgetModel {
    strategy: string | Strategy<IndicatorImportViewModel> | null;
}

export interface SearchAlphaWidgetViewModel extends Omit<SearchAlphaWidgetModel, 'strategy'> {
    // UI always materializes the strategy
    strategy: Strategy;
}

export type WidgetViewModel =
    | AccountsWidgetModel
    | AggregatedPositionsWidgetModel
    | ExecutionsWidgetViewModel
    | IfThenBuilderWidgetViewModel
    | SearchAlphaWidgetViewModel
    | LocatesWidgetModel
    | MessagesWidgetViewModel
    | OrderBlotterWidgetModel
    | SimpleOrderEntryWidgetModel
    | OrderEntryWidgetModel
    | OrdersWidgetViewModel
    | PositionsWidgetViewModel
    | StrategyWidgetViewModel
    | TableWidgetModel
    | NewsAndEventsWidgetViewModel
    | TimeSeriesWidgetModel
    | AlphaLensWidgetModel;

export type WidgetViewModelKinds = Array<WidgetViewModel['type']>[number];

export type InferWidgetViewModel<T extends WidgetViewModelKinds> = Extract<WidgetViewModel, { type: T }>;

export interface WidgetTabViewModel<T extends WidgetViewModel = WidgetViewModel> extends WidgetTab {
    containerId: string;
    channelId: string | null;
    isActive: boolean;
    widget: T;
    // Poorly named dummy properties to not forget future plans
    // channelSubscriptionSettings?: {
    //     dontPublishTo?: WidgetType[];
    //     dontListenTo?: WidgetType[];
    //     publicationPriorities: [];
    // };
}

export const ApplicationMenu = {
    loggedOutOpen: 'logged-out::open',
    loggedOutClosed: 'logged-out::closed',
    dashboard: 'dashboard',
};

export type ApplicationMenuType = (typeof ApplicationMenu)[keyof typeof ApplicationMenu];

export type VerifyLeavingWorkspaceModal = {
    type: 'verify-leaving-workspace';
};

export type VerifySavingWorkspaceModal = {
    type: 'verify-saving-workspace';
};

export type GetNewWorkspaceNameModal = { type: 'get-new-workspace-name'; title: string };
export type WorkspaceLibraryModal = { type: 'workspace-library' };

export type ModalInstance =
    | VerifyLeavingWorkspaceModal
    | VerifySavingWorkspaceModal
    | GetNewWorkspaceNameModal
    | WorkspaceLibraryModal;

export interface ClientCredentials {
    clientId: string;
    clientSecret: string;
}
