// eslint-disable-next-line no-restricted-imports
import { cva as _cva } from 'class-variance-authority';
// eslint-disable-next-line no-restricted-imports
import type { ClassValue } from 'clsx';
// eslint-disable-next-line no-restricted-imports
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

// eslint-disable-next-line no-restricted-imports
export type { VariantProps } from 'class-variance-authority';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function cva<T>(...inputs: Parameters<typeof _cva<T>>): ReturnType<typeof _cva<T>> {
    const getClassName = _cva(...inputs);
    return (...args: Parameters<typeof getClassName>) => {
        return twMerge(getClassName(...args));
    };
}
