import type { CustomEditor } from '../../slate';
import { Text } from '../../slate/helpers';
import { type CallNode } from '@thinkalpha/language-services';
import { Transforms, Node, Editor, type Path, type Point } from 'slate';

export const updateIndicatorNode = (
    originalCallNode: CallNode,
    replacementCallString: string,
    editor: CustomEditor,
) => {
    // Step 1: Determine the range of elements to be replaced
    const startOffset = originalCallNode.ranges.node.start;
    const endOffset = originalCallNode.ranges.node.end;

    let replacementsStartPath: Path | undefined;
    let replacementsEndPoint: Point | undefined;

    let currentOffset = 0;
    for (const [child, path] of Editor.nodes(editor, { at: [0], match: (node) => Text.cheapIsText(node) })) {
        const text = Node.string(child);
        const textLength = text.length;

        if (currentOffset + textLength > startOffset && !replacementsStartPath) {
            replacementsStartPath = path;
        }

        if (currentOffset + textLength === endOffset && !replacementsEndPoint) {
            replacementsEndPoint = { path, offset: textLength };
        }

        currentOffset += textLength;
    }

    if (!replacementsStartPath) {
        replacementsStartPath = [0, 0];
    }
    if (!replacementsEndPoint) {
        replacementsEndPoint = Editor.end(editor, [0]);
    }

    // Step 2: Replace the identified elements
    // const value = render(replacementCallNode);
    Transforms.insertText(editor, replacementCallString, {
        at: { anchor: { path: replacementsStartPath, offset: 0 }, focus: replacementsEndPoint },
    });
};
