import type { FormulaServiceRequest, FormulaServiceResponse } from './types';
import type { ConcreteDataType, ParserResult } from '@thinkalpha/language-services';
import { canConvert, getNameForDataType, iterateAst } from '@thinkalpha/language-services';
import { injectable } from 'inversify';
import type { FormulaService } from 'src/ioc/types/FormulaService';
import { fakeConstStringParserResult } from 'src/lib/parser';
import type { AnalyzerFunction } from 'src/lib/parser';
import { reviveObject } from 'src/lib/serializer';
import { v4 as uuid } from 'uuid';

@injectable()
export class FormulaServiceImpl implements FormulaService {
    #worker: Worker;

    constructor() {
        this.#worker = new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' });
    }

    async #sendWorkerRequest(request: FormulaServiceRequest): Promise<ParserResult> {
        this.#worker.postMessage(request);

        return new Promise((resolve) => {
            const listener = (e: MessageEvent<FormulaServiceResponse>) => {
                if (e.data.id === request.id) {
                    this.#worker.removeEventListener('message', listener);

                    const result = e.data.result;

                    iterateAst(result.root).forEach((node) => Object.assign(node, reviveObject(node as any)));

                    resolve(result);
                }
            };
            this.#worker.addEventListener('message', listener);
        });
    }

    async parse(
        formula: string,
        analyzers: AnalyzerFunction[] = [],
        equalsMode?: boolean,
        dataTypeRequired?: ConcreteDataType,
    ): Promise<ParserResult> {
        if (equalsMode && !formula.startsWith('=')) {
            return fakeConstStringParserResult(formula);
        }

        const parseableFormula = (equalsMode ? formula.slice(1) : formula) ?? '';

        let parserResult = await this.#sendWorkerRequest({
            type: 'parse',
            formula: parseableFormula,
            id: uuid(),
        });

        if (parserResult.root) {
            for (const analyzer of analyzers) analyzer(parserResult.root);
        }

        if (dataTypeRequired) {
            if (
                !parserResult.root ||
                !parserResult.root.dataType ||
                !canConvert(parserResult.root.dataType, dataTypeRequired)
            ) {
                const newError = {
                    text:
                        `Expected type ${getNameForDataType(dataTypeRequired)}` +
                        (parserResult.root && parserResult.root.dataType
                            ? `, but instead found ${getNameForDataType(parserResult.root.dataType)}`
                            : ''),
                    range: { start: 0, end: parserResult.text.length },
                    source: 'if-then',
                };
                parserResult = { ...parserResult, errors: [...parserResult.errors, newError], valid: false };
            }
        }

        return parserResult;
    }
}
